@charset "UTF-8";
/* LINK
---------------------------------------------------- */

span.indent{
  display:inline-block;
  width:1rem;
}

a {
  text-decoration: none;
  &:visited {
    color: var(--primary);
    text-decoration: none;
  }
}

b {
  font-weight: 700;
}

p {
  text-align: justify;
}

.small {
  font-size: 80%;
}

.blue {
  color: #646464;
}

.white{
  color:#ffffff;
}

.gray{
  color: #efeded;
}

.primary{
  color:var(--primary);
}

.gold {
  color: var(--gold);
}


@media screen and (min-width: 769px) {
  .pc768 {
    display: block;
  }

  .forSP {
    display: none;
  }

  h2 {
    font-size: 140%;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
  }

  h2 {

    padding: 6px 50px;
    min-width:420px;
    display: inline-block;
    color: #f0f0f2;
    text-align: center;
    margin-bottom:1rem;
  }

  h3 {
    font-size: 125%;
    font-weight: 600;
    margin-bottom: 1em;
  }

  h4 {
    font-size: 110%;
    font-weight: 600;
    line-height: 1.6;
  }

  h5 {
    font-size: 100%;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
  }

  h6 {
    font-size: 90%;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
  }

  p {
    font-weight: 400;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 120%;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
  }

  h2 {
    padding: 6px 50px;
    width:100%;
    display: inline-block;
    color: #f0f0f2;
    text-align: center;
    margin-bottom:1rem;
  }

  h3 {
    font-size: 112%;
    font-weight: 600;
    margin-bottom: 1em;
  }

  h4 {
    font-size: 100%;
    font-weight: 600;
    line-height: 1.5;
  }

  h5 {
    font-size: 95%;
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
  }

  h6 {
    font-size: 85%;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
  }

  p {
    font-weight: 400;
    margin-bottom: 12px;
  }

  .pc768 {
    display: none;
  }

  .forSP {
    display: block;
  }
}

