#photos{
  .slider{
    height:200px;
    over-flow:hidden;
    width:100%;
    img {
      height:200px;
      width:auto;
    }

    @media screen and (max-width: 768px) {
      height:160px;
      img {
        height:160px;
      }
    }

    .slick-slide{
      transform: scale(1);
    }
  }
}
