@include keyframes(fadeIn){
  from {opacity: 0;}
  to {opacity: 1;}
}

@include keyframes(fadeOut){
  from {opacity: 1;}
  to {opacity: 0;}
}


@include keyframes(fadeOutScaleIn){
  from {opacity: 1; transform:scale(1.0)
  }
  to {opacity: 0; transform:scale(1.4)}
}

@include keyframes(slideShineAnime) {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    opacity: 0.8;
    transform: translateX(100%);
  }
}


@-webkit-keyframes animate-svg-fill-white {
  0% {
    fill: transparent;
  }

  30% {
    fill: rgb(0,0,0);
  }

  80% {
    fill: rgb(255,255,255);
  }
}

@keyframes animate-svg-fill-white {
  0% {
    fill: transparent;
  }

  30% {
    fill: rgb(0,0,0);
  }

  80% {
    fill: rgb(255,255,255);
  }
}

@keyframes rumble {
  0%     { transform:translate(0, 0) rotate(0); }
  16.66% { transform:translate(4px, -2px) rotate(-5deg); }
  33.32% { transform:translate(4px, 2px) rotate(5deg); }
  49.98% { transform:translate(0, 0) rotate(0); }
  66.64% { transform:translate(-4px, -2px) rotate(5deg); }
  83.30% { transform:translate(-8px, 2px) rotate(-5deg); }
  100%   { transform:translate(0, 0) rotate(20deg); }
}

@keyframes muramoto-long {
  0%     {
    transform:translate(-1000px);opacity:0; }
  5%     { transform:translate(-300px, 0px); opacity:0.1; }
  10%     { transform:translate(-200px, -23px); opacity:0.3; }
  15%     { transform:translate(-150px, 0px); opacity:0.7; }
  20%     { transform:translate(-100px, -20px); opacity:1; }
  25%     { transform:translate(-50px, 0px); opacity:1; }
  30%     { transform:translate(-20px, -19px); opacity:1; }
  35%     { transform:translate(0px, 0px); opacity:1; }
  85%     { transform:translate(0px, 0px);
    filter: invert(0%);
    opacity:1; }
  100%   { transform:translate(0px);
    filter: invert(100%);
  }
}

@-webkit-keyframes animate-svg-fill-black {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-black {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}
