@charset "UTF-8";
*, :after, :before {
  box-sizing: border-box !important;
}
img {
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
}

.sp_u {
  display: none;
}

.pc_u {
  display: initial;
}

@media screen and (max-width: 580.98px) {
  .sp_u {
    display: initial;
  }

  .pc_u {
    display: none;
  }
}

html {
  width: 100%;
  height: 100%;
  background-color: var(--black);
  font-family: var(--font-family-main);

}

body {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.lang_switcher{
  user-select:none;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:3px;
  color:var(--primary);
  padding:1px 8px;
  border:thin solid #999;
  margin:4px;
  border-radius:4px;

  span{
    font-weight:bold;
    color:white;
    cursor:pointer;
  }

  span.jp{
    color:var(--primary);
  }

  &.en{
    span.en{
      color:var(--primary);
    }

    span.jp{
      color:var(--white);
    }
  }

}
