
#credits{
  background-color: var(--white);
  color:var(--black);
  background-image: url('https://iamacomedian.jp/images/iac_credits_bg.jpg');
  background-size: cover;
  background-position: left top;
  @media screen and (max-width: 768px) {
    background-image: none;
  }
}



.credits_wrapper {
  padding: 50px 20px 80px 20px;
  color:white;
  font-size:0.8rem;
  font-family:var(---font-family-hiragino);
  letter-spacing:1px;

  .credits_inner{
    margin:60px auto 0 auto;
    max-width:1000px;
    padding:0 24px;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:20px;

    a{
      color:var(--black);
      text-decoration: underline;
    }

    a.logos{
      img {
        height: 20px;
      }
    }
    p{
      display:flex;
      justify-content: center;
      align-items: center;
      gap:12px;
      flex-wrap:wrap;
      width:100%;
      margin:10px auto;
    }
    span{
      line-height:1.2;
      font-size:1rem;

      &.label{
        color:var(--primary);
        font-size:0.9rem;
      }

      &.large{
        font-size:1.5rem;
        position:relative;
        top:-1px;
        margin-right:3px;
      }

      &.small{
        font-size:0.9rem;
      }

      &.oneline{
        white-space: nowrap;
        letter-spacing: 0;
      }
    }
    img.logos{
      width:auto;
      margin:0 5px;
    }

    @media screen and (max-width: 768px) {
      gap:12px;
      padding: 10px 10px 30px 10px;
      letter-spacing:0;
      p{
        justify-content: flex-start;
      }
      span {
        white-space:nowrap;
        font-size:0.7rem;

        &.label{
          font-size:0.6rem;
          display:block;
          width:100%;
          color:var(--primary);
        }

        &.large{
          font-size:1.2rem;
        }

        &.small{
          font-size:0.6rem;
        }

        a.logos{
          img {
            height: 12px;
          }
        }
        &.oneline{
          letter-spacing: -0.5px;
        }

      }
    }
  }
}

