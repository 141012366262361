#svg_logo_div {
  .svg-iam {
    -webkit-animation: animate-svg-fill-white 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
    animation: animate-svg-fill-white 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  }
  .svg-iam2 {
    -webkit-animation: animate-svg-fill-white 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
    animation: animate-svg-fill-white 0.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  }
  .svg-iam3 {
    -webkit-animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
    animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
  }
  .svg-iam4 {
    -webkit-animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
    animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  }
  .svg-iam5 {
    -webkit-animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
    animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  }

  .svg-Jaiam {
    -webkit-animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
    animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  }

  .svg-Jmedia {
    -webkit-animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
    animation: animate-svg-fill-white 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s both;
  }

  .svg-black1 {
    -webkit-animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
    animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  }
  .svg-black2 {
    -webkit-animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
    animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  }
  .svg-black3 {
    -webkit-animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
    animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
  }
  .svg-black4 {
    -webkit-animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both;
    animation: animate-svg-fill-black 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both;
  }

  .svg-rumble {
    //transform-origin:center;
    //-webkit-animation: rumble 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
    //animation: rumble 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }
}

.muramoto_long {
  width:auto;
  height:auto;
  transform-origin:center;
  -webkit-animation: muramoto-long 1.0s cubic-bezier(0.47, 0, 0.745, 0.715) 0.0s both;
  animation: muramoto-long 1.0s cubic-bezier(0.47, 0, 0.745, 0.715) 0.0s both;
}

