

#insert1{
  .subBlock{
     height:100%;

    display:flex;
    justify-content: flex-end;
    align-items: center;
    img.word{
      height:90%;
      width:auto;
    }

    .kotoba {
      background: url(https://iamacomedian.jp/images/insert1_main.png) no-repeat;
      background-size: contain;
      background-position: 100% 0;
      background-repeat: no-repeat;
      width: auto;
      height:100%;
      //position: absolute;
      top: 0px;
      right: 0;

      .sub{
        height:100%;
        width:auto;

      }
    }
  }

}

@media screen and (min-width: 769px) {
  #insert1 {
    width: 100%;
    height: 657px;
    background: url(https://iamacomedian.jp/images/iac_intro1_bg.jpg) center top no-repeat;
    background-size: auto 100%;
  }
}

@media screen and (max-width: 768px) {
  #wrapper #insert1 {
    padding-top:12px;
    position: relative;
    width: 100%;
    height: 96vw;
    background: url(https://iamacomedian.jp/images/iac_intro1_bg.jpg) center top no-repeat;
    background-size: auto 100%;

    img.word{
      height:80%;
    }
  }
}


