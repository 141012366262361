#hero {
  background-image: url("https://iamacomedian.jp/images/iac_bg_curtain.jpg");
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin: 0;
  position:relative;
  gap:60px;

  .trans_muramoto{
    position:absolute;
    top:0;
    left:0;
    background-image: url("https://iamacomedian.jp/images/webp/iac_bg_muramoto_trans.webp");
    background-position: center bottom;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    @media screen and (max-width: 920px) {
      background-image: url("https://iamacomedian.jp/images/webp/iac_bg_muramoto_trans_sp.webp");
      background-position: center bottom;
      background-size: auto 100%;
    }
  }

  h1{
    position:absolute;
    opacity:0;
  }

  .hero_left{
    width:30%;
    height:84vh;
    justify-content: space-between;
    text-align:center;
    img{
      width:90%;
      margin:0 auto;
      height:auto;
    }

    .with_english{
      z-index:200;
      display:flex;
      //justify-content: center;
      align-items: center;
      //font-style: italic;
      width:100%;
      //background-color:var(--black);
      background-image: linear-gradient(to right, #000000 5%, #000000 25%,  #000000,  #000000 75%,   #00000000 95%);
      padding:6px;
      margin:0 12px;
      gap:12px;

      img{
        margin:0;
        height:28px;
        width:auto;
      }

      p{
        line-height:1.5;
        color:var(--white);
        font-weight:bold;
        font-size:0.9rem;
        letter-spacing: 1px;
        margin:0;
        text-align:left;
      }
      @media screen and (max-width: 768px) {
        padding:4px;
        margin:0;
        gap:6px;
        img{
          height:20px;
          font-size:0.7rem;
        }

        p{
          letter-spacing:0;
          white-space: nowrap;
        }
      }
    }
  }

  .hero_center{
    width:20%;
    max-width:70px;
    img{
      width:100%;
    }
  }

  .hero_right{
    position:relative;
    text-align:center;
    width:30%;
    height:84vh;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    gap:10px;
    img {
      width:90%;
      height:auto;
      margin:0 auto;
    }

    .news_list{
      order:1;
      ul{
        max-height:80px;
        display:flex;
        flex-direction: column;
        gap:3px;
        overflow:scroll;
      }
    }

    .top_logo{
      order:2;
    }
    @media screen and (max-width: 768px) {
      .news_list{
        order:2;
      }

      .top_logo{
        order:1;
      }
    }

  }

  @media screen and (max-width: 1180px) {
    gap:45px;
    .hero_left{
      width:32%;
    }
    .hero_right{
      width:32%;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top:55px;
    flex-direction: column;
    gap:15px;
    .hero_left{
      width:86%;
      height:auto;
      img{
        width:100%;
      }
    }

    #mvtk-widgets-container{
      position:initial;
      //width:94%;
      margin:0 auto;
    }
    .hero_right{
      width:94%;
      height:auto;
    }

    .hero_center{
      max-width:52px;
    }
  }
}

