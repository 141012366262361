/* TRAILER
----------------------------------------------------- */

#trailer {
  text-align: center;
  background-color: var(--black);
}

/* 動画を囲うdiv */

.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  /* 高さを指定(16:9) */

  iframe, & > img {
    cursor:pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* YouTube埋め込み用のiframe */

@media screen and (min-width: 769px) {}

@media screen and (max-width: 768px) {}


.modal-video-body{
  background-color: black;
  width:100%;
  max-width:1800px;
}
