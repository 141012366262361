/* NEWS
----------------------------------------------------- */

.news_list{
  position:relative;
  cursor:pointer;
  padding:7px;
  width:100%;
  max-width:720px;
  font-size:0.8rem;
  color: white;
  font-weight:bold;
  text-align:left;
  background-color: rgba(14, 13, 13, 0.71);
  mix-blend-mode:hard-light;
  @media screen and (max-width: 668px) {
      max-width:650px;
  }

  span{
    position:absolute;
    top:-20px;
    left:0;
    background-color: #fd0d59;
    line-height:1.4;
    font-size:0.8rem;
    font-weight:bold;
    padding:1px 0;
    color:var(--black);
    text-align: center;
    margin:5px 5px 8px 0;
    min-width:60px;
    display:inline-block;
  }

  a {
    color:white;
    width:auto;
    height:100%;
    max-width:300px;

  }
    ul{
      margin: 0 auto;
      li{
        font-weight:bold;
        line-height:1.8;
        display:flex;
        align-items: center;
        gap:5px;
        &:before {
        content:'';
          display:inline-block;
          background-image: url('https://iamacomedian.jp/images/iac_mic_icon.png');
          background-size: contain;
          background-repeat: no-repeat;
          width:20px;
          height:20px;
        }
      }
    }
}

#wrapper.news_page{
  //border-left: 8px solid var(--primary);
  //border-right: 8px solid var(--primary);
  background-image: url('https://iamacomedian.jp/images/iac_footer_bg.jpg');
  background-size: cover;
  background-position: center bottom;
}

#news {
  background-color: rgba(0, 0, 0, 0.56);
  background-size: cover;
  background-position: center 100%;
  padding-bottom:380px !important;
  color:var(--white);
  a {
    color: var(--primary);
  }

  .subtitle{
    font-size:3rem;
    font-weight:bold;
    color:var(--primary);
    border-bottom:thin solid var(--primary);
  }

  .subBlock {
    border-radius:0;
    border:thin solid var(--primary);
    border-top:12px solid var(--primary);
    border-bottom:12px solid var(--primary);
    padding:34px 10px 10px 10px;
    text-align: center;
    margin: 40px auto 40px auto;
    position:relative;

    span.news_no{
      background-color: var(--primary);
      padding:2px 7px;
      border-bottom:thin solid var(--primary);
      position:absolute;
      right:0;
      top:0;
      color: white;

      &.new{
        &:before{
          line-height:1;
          position:absolute;
          top:-24px;
          right:5px;
          content:'new!';
          display:block;
          background-color: white;
          color:var(--primary);
          font-weight:bold;
          font-style:italic;
          border-radius:3px;
          padding:4px;
        }
      }

    }

    .news_images{
      display:flex;
      width:98%;

      margin:10px auto;
      gap:10px;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      img{
        flex:1;
        max-width:300px;
        @media screen and (max-width: 668px) {
          max-width:100%;
        }
      }
    }
    p {
      text-align: left;

      &.center{
        text-align: center;
      }

      span{
        min-width:110px;
        margin-right:10px;
        padding:0 3px;
        color:var(--white);
        display:inline-block;
        background-color: var(--primary);
        font-size:0.86em;
      }

      blockquote{
        text-indent:120px;

        &.small{
          font-size:0.8em;
        }
      }
    }
  }

  h3 {
    padding: 6px 12px;
    display: inline-block;
    margin-bottom:1.5rem;
    color: var(--primary);
    border-bottom:thin solid var(--primary);
  }
}


@media screen and (max-width: 768px) {
  #news {
    background-attachment: fixed;

    .subBlock {
      text-align: center;
      margin: 20px auto 20px auto;

      h3{
        padding:4px;
      }

      p{
        font-size:0.8rem;
        span{
          display:block;
          text-align:center;
          margin:2px 0;
          padding:3px;
        }

        blockquote{
          text-indent:0;
        }
      }
    }
  }
}

