.section_preloader{
  @include noscroll();
  @include flex();
  justify-content: center;
  align-items: center;
  user-select:none;
  pointer-events: none;
  min-height:300px;
  min-width:100%;
  width:100%;
  @media screen and (max-width: 768px) {
    min-height:90px;
  }

  //opacity: 1;
  &.show{
    @include animation(fadeOut 2.0s ease-in 0.3s forwards);
    opacity: 1;
  }

  &.longer{
    @include animation(fadeOut 12.0s ease-in 0s forwards);
    opacity: 1;
  }
}


#preloader{
  @include noscroll();
  display:flex;
  justify-content: center;
  align-items: center;
  user-select:none;
  pointer-events: none;
  position:fixed;
  top:0;
  left:0;
  margin:0;
  width:100vw !important;
  min-width:100vw;
  height:100vh;
  z-index:200;
  background-color: var(--primary);
  &.slide-in{
    @include animation(fadeOutScaleIn 0.5s ease-in 0s forwards);
  }
  .logos{
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    height:100%;
    width:100%;
    z-index:10;

    #svg_logo_div {
      width:100%;
      max-width:900px;
    }

    .muramoto_long{
      position:absolute;
      left:calc(50vw - 900px);
      mix-blend-mode: hard-light;
    }

  }

  .img_area{
    display:flex;
    justify-content: center;
    align-items: center;
    width:60%;
    @media screen and (max-width: 768px) {
      width:90%;
    }
    z-index:10;
    max-with:350px;
    margin:0 auto;
  }

}
